import Test from './views/Test';
import Home from './views/Home';
import Login from './views/Authentication/Login';
import Logout from './views/Authentication/Logout';
import EquipmentAdd from './views/Equipment/EquipmentAdd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faDolly, faHome,  faLuggageCart, faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons';
import EquipmentList from './views/Equipment/EquipmentList';
import EquipmentDetail from './views/Equipment/EquipmentDetail';
import EquipmentSuitability from './views/Equipment/EquipmentSuitability';
import GroupAdd from './views/Group/GroupAdd';
// import UserGroupList from './views/Group/silinecek-UserGroupList';
import GroupList from './views/Group/GroupList';

import Settings from './views/Settings';
import UserDetail from './views/User/UserDetail';
import store from './components/stores/index'
import UserAdd from './views/User/UserAdd';
import UserCard from './views/User/UserMultipleDetail';
import UserList from './views/User/UserList';
import NotFound from './components/Error/NotFound';

import GroupCard from './views/Group/GroupMultipleDetail';
import GroupDetail from './views/Group/GroupDetail';

import ShipmentAdd from './views/Shipment/ShipmentAdd';
import ShipmentCard from './views/Shipment/ShipmentMultipleDetail';
import ShipmentList from './views/Shipment/ShipmentList';
import ShipmentDetail from './views/Shipment/ShipmentDetail';
import EquipmentOption from './views/Equipment/EquipmentOption';
import EquipmentMultipleDetail from './views/Equipment/EquipmentMultipleDetail';

const mainRoutes = [
  {
    path:'/giris-yap',
    exact:true,
    component:Login,
    props:{},
    auth:false
  },
  {
    path:'/test',
    label:'TEST',
    exact:false,
    component:Test,
    props:{},
    auth:false
  },
  {
    path:'/cikis-yap',
    exact:true,
    component:Logout,
    props:{},
    auth:false
  },
  {
    path:'/',
    exact:true, 
    component:Home,
    props:{},
    label:'Ana Sayfa',
    icon:<FontAwesomeIcon icon={faHome} />,
    auth:true
  },
  {
    path:'/ayarlar',
    exact:true, 
    component:Settings,
    props:{},
    auth:true
  },
  {
    path:'/ekipman',
    exact:true, 
    component:Home,
    label:'Ekipman Yönetimi',
    icon:<FontAwesomeIcon icon={faLuggageCart} />,
    props:{ 
      items:[
        {label:'Ekipman Oluştur',exact:true,path:'/ekle',component:EquipmentAdd},
        {label:'Ekipman Listesi',exact:true,path:'/liste',component:EquipmentList},
        {label:'Ekipman Uygunluk',exact:true,path:'/uygunluk',component:EquipmentSuitability},
        {label:'Ekipman Seçenekleri',exact:true,path:'/secenek',component:EquipmentOption},
      ]
    },
    auth:true
  },
  {
    path:'/ekipman/guncelle/:id',
    exact:true, 
    component:EquipmentDetail,
    props:{ 
    },
    auth:true
  },
  // {
  //   path:'/sevkiyat',
  //   exact:true, 
  //   component:Home,
  //   label:'Sevkiyat Yönetimi',
  //   icon:<FontAwesomeIcon icon={faTruckMoving} />,
  //   props:{ 
  //     items:[
  //       {label:' ',exact:true,path:'/',component:Home},
  //     ]
  //   },
  //   auth:true
  // },
  // {
  //   path:'/raporlar',
  //   exact:true, 
  //   component:Home,
  //   label:'Raporlar',
  //   icon:<FontAwesomeIcon icon={faFileAlt} />,
  //   props:{ 
  //     items:[
  //       {label:'',exact:true,path:'/',component:Home}
  //     ]
  //   },
  //   auth:true
  // },
  {
    path:'/grup',
    exact:true, 
    component:Home,
    label:'Grup Yönetimi',
    icon:<FontAwesomeIcon icon={faUserShield} />,
    props:{ 
      items:[
        {label:'Yeni Grup Oluştur',exact:true,path:'/ekle',component:GroupAdd},
        // {label:'Kullanıcı Grup Listesi',exact:true,path:'/liste',component:UserGroupList},
        {label:'Grup Listesi',exact:true,path:'/liste',component:GroupList},
      ]
    },
    auth:true
  },
  {
    path:'/kullanici',
    exact:true, 
    component:UserList,
    label:'Kullanıcı Yönetimi',
    icon:<FontAwesomeIcon icon={faUsers} />,
    props:{ 
      items:[
        {label:'Yeni Kullanıcı Oluştur',exact:true,path:'/ekle',component:UserAdd},
        {label:'Kullanıcı Listesi',exact:true,path:'/liste',component:UserList},
      ]
    },
    auth:true
  },
  {
    path:'/sevkiyat',
    exact:true, 
    component:ShipmentList,
    label:'Sevkiyat Yönetimi',
    icon:<FontAwesomeIcon icon={faDolly} />,
    props:{ 
      items:[
        {label:'Yeni Sevkiyat Oluştur',exact:true,path:'/ekle',component:ShipmentAdd},
        {label:'Sevkiyat Listesi',exact:true,path:'/liste',component:ShipmentList},
      ]
    },
    auth:true
  },
  {
    path:'/sevkiyat/guncelle/:id',
    label:'Sevkiyat Güncelle',
    exact:true, 
    component:ShipmentDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/sevkiyat/guncelle',
    label:'Sevkiyat Güncelle',
    exact:true, 
    component:ShipmentCard,
    props:{ 
    },
    auth:true
  },
  {
    path:'/ekipman/guncelle/:id',
    label:'Ekipman Güncelle',
    exact:true, 
    component:EquipmentDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/ekipman/guncelle',
    label:'Ekipman Güncelle',
    exact:true, 
    component:EquipmentMultipleDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/grup/guncelle/:id',
    label:'Grup Güncelle',
    exact:true, 
    component:GroupDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/grup/guncelle',
    label:'Grup Güncelle',
    exact:true,
    component:GroupCard,
    props:{},
    auth:false
  },
  {
    path:'/kullanici/guncelle/:id',
    exact:true, 
    label:'Kullanıcı Güncelle',
    component:UserDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/kullanici/guncelle',
    exact:true, 
    label:'Kullanıcı Güncelle',
    component:UserCard,
    props:{ 
    },
    auth:true
  },
  
]


const getRoutes=()=>{
  const routes=store.getState().auth.routes
  return (
    mainRoutes.map((route,index)=>{
      if(route.path.path=='/giris-yap' || routes==null || routes==undefined || route.path=='/cikis-yap' ||route.path=='/ayarlar' || route.path=='/' || route.label=='TEST'){
        return route
      }
      if(route.auth==true){
        
        if(route.props.items){
          let check=route.props.items.filter(i=>{
            if((routes.filter(xi=>xi.permName==i.label && xi.value==true)).length>0){
              return true
            }
          })
          if(check.length>0){
            return {
              ...route,
              props:{
                ...route.props,
                items:check
              }
            }
          }else{
            if((routes.filter(xi=>xi.permName==route.label && xi.value==true)).length>0){
                return {
                ...route,
                props:{
                  ...route.props,
                  items:undefined
                }
              }
            }
          }
          return {component:<NotFound/>,path:`error/${index}`,icon:'',props:{}}
        }else{
          if((routes.filter(xi=>xi.permName==route.label && xi.value==true)).length>0){
              return {
                ...route,
                props:{
                  ...route.props,
                  items:undefined
                }
              }
          }
        }
      }else{
        return route
      }

    })
  )
}

const Routes=getRoutes()

export {Routes}