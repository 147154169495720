import React,{useState,createRef,useRef} from 'react'
import Menu from '../components/Navigation/Menu'
import {Content,ContentForm} from '../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../components/Content/Title'
import Chart from 'react-google-charts'
import {useDispatch} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faWrench } from '@fortawesome/free-solid-svg-icons';
import NotifyContent from '../components/Content/NotifyContent'
import Support from '../components/Support'
import { Button } from '@material-ui/core'
import { Helmet } from 'react-helmet';







export default function Home(props) {  
  const ref = createRef(null);


  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)

  const [notifyData,setNotifyData]=useState(
      [
        {
          name:'Yenilikler',
          icon:<FontAwesomeIcon icon={faWrench} />,
          items:[{title:'Ana Sayfa Hk.',content:'Bildirim ve Yenilikler ana sayfada yerini aldı. Arka plan sistemine entegresi için bekleniyor.'}]
        },
        {
          name:'Bildirimler',
          icon:<FontAwesomeIcon icon={faBell} />,
          items:[{title:'Bilmemne hk.',content:'TEST2 TE2ST'}]
        },
       ]
  )

  const dispatch = useDispatch() 
  const searchRef=useRef(null)
  const ganttChartData = [
  [
    { type: 'string', label: 'A' },
    { type: 'string', label: 'b' },
    { type: 'date', label: 'c' },
    { type: 'date', label: 'd' },
    { type: 'number', label: '2' },
    { type: 'number', label: '1' },
    { type: 'string', label: 'g' },
    
  ],
  [
    'Test',
    'test2',
    new Date(2015, 0, 1),
    new Date(2017, 0, 5),
    null,
    100,
    null,
  ],
  [
    'Test3',
    'test4',
    new Date(2013, 0, 1),
    new Date(2015, 0, 5),
    null,
    30,
    null,
    
  ],[
    'Test4',
    'test5',
    new Date(2013, 1, 1),
    new Date(2018, 3, 5),
    null,
    30,
    null,
    
  ],
  [
    'Test6',
    'test7',
    new Date(2018, 0, 1),
    new Date(2020, 0, 5),
    null,
    30,
    null,
    
  ],
  ]

  
  return (
    <React.Fragment>
      <Menu/>
      <Helmet>
        <title>Ana Sayfa</title>
      </Helmet>
      <Content ref={ref} sidebar={sidebar}  >
        <Support refer={ref} />
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title text={<><FontAwesomeIcon icon={faHome} /> Ana Sayfa</>} back={false} />
          
          {/* 
          
          <button
            onClick={()=>{
            dispatch(changePopup(<ul><li>a</li><li>b</li></ul>))
            }} >Test butonu</button> 
          
          <Chart
              options={{backgroundColor:'#fec'}}
              width={'100%'}
              height={'30vh'}
              chartType="Bar"
              loader={<div>Grafik yükleniyor</div>}
              data={ganttChartData}
              chartLanguage={'tr'}
              legendToggle
            /> */}

            <NotifyContent
            data={notifyData}
            />
          </form>

        </ContentForm>
            
      </Content>
    </React.Fragment>
  )
}
