import React,{useState,useEffect} from 'react'
import {AuthContainer,useStyles} from '../../styles/AuthStyle'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Copyright from '../../components/Copyright'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useSelector,useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {login,setRoutes} from '../../components/stores/Auth'
import { encode as base64_encode} from 'base-64';
import axios from 'axios';
import { Sentry } from "react-activity";
import rentAs from '../../images/asgrouplogo2.png'
export default function Login() {

  const dispatch = useDispatch()
  const history=useHistory()
  
  localStorage.removeItem('background')
  const classes = useStyles()

  const [error,setError]=useState('')
  const [post,setPost]=useState(false)
  const [username,setUsername] = useState(localStorage.getItem('username')!=null && localStorage.getItem('username')!=undefined  ? localStorage.getItem('username') : 'ms')
  const [password,setPassword] = useState(localStorage.getItem('password')!=null && localStorage.getItem('password')!=undefined  ? localStorage.getItem('password') : '1')
  const [remember,setRemember] = useState(localStorage.getItem('remember')=='true' ? true : false)
  const labelProps= {
    classes: {
      root: classes.cssLabel,
      focused: classes.cssFocused,
    },
  }
  const inputProps= {
    classes: {
      root: classes.cssOutlinedInput,
      focused: classes.cssFocused,
      notchedOutline: classes.notchedOutline,
    }
  }
  
  const getRoutes= (token,id)=>{
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_MAIN+(process.env.REACT_APP_API_GET_USER_PERMISSIONS.replace('-id-',id)) ,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then(async response=>{
      dispatch(setRoutes(response.data.data.permissions))
      history.push('/')
      localStorage.setItem('group',response.data.data.groupName)
      setTimeout(() => {
        history.go(0)
      }, 50);
    })
    .catch(error=>{
      setTimeout(() => {
        history.go(0)
      }, 50);
    })
  }
  const loginCheck=async (e)=>{

    e.preventDefault()
    setPost(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_MAIN+process.env.REACT_APP_API_AUTHENTICATE,
      data: {
        username: username,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST',
      }
    })
    .then(function (response) {
      setError('')
      dispatch(login({
        user:{username:username,id:response.data.identity},
        token:"bearer "+response.data.token,
        status:'admin',
      }))
      localStorage.setItem('titlename',response.data.name)
      if(remember){
        localStorage.setItem('username',username)
        localStorage.setItem('password',password)
        localStorage.setItem('remember','true')
      }
      
      getRoutes("bearer "+response.data.token,response.data.identity)
    })
    .catch(function (error) {
      setPost(false)
      setError(error.response.data)
      
    });
    /**
    
    
     */

  }

  

  return (
    <AuthContainer>
      <form onSubmit={loginCheck}>
        <img src={rentAs} style={{zIndex:99}} onDragStart={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()} />
        <TextField 
        InputLabelProps={labelProps} InputProps={inputProps}
        value={username} onChange={(e)=>setUsername(e.target.value)}
        autoComplete='off' required id="outlined-basic" label="Kullanıcı Adı" variant="outlined" />

        <TextField InputLabelProps={labelProps} InputProps={inputProps} 
        value={password} onChange={(e)=>setPassword(e.target.value)}
        autoComplete='off' required type={'password'} id="outlined-basic" label="Parola" variant="outlined" />
        <div className='remember'>
          <FormControlLabel
          label="Beni hatırla"
          control={ <Checkbox checked={remember} onChange={()=>setRemember(!remember)} size="small" inputProps={{ 'aria-label': 'checkbox with small size' }} />}
          />
        </div>
        {
          error!='' ? <div className='error'>{error}</div> : null
        }
        {
          post ? 
          <div className='loading'><Sentry size={33}/></div> 
          : 
          <Button type="submit" variant="contained" color="secondary" style={{color:'#666'}}>Giriş Yap</Button>
        }
        <div className='bottomarea'>
          {/* <Link to="/sifremi-unuttum" >Şifremi Unuttum?</Link> */}
          {/* <Link to="/kayit-ol" >Kayıt Ol</Link> */}
        </div>

        <Copyright />
      </form>
    </AuthContainer>
  )
}
